import React from "react";
import {GridRowModesModel} from "@mui/x-data-grid";
import {FDataGrid} from "../../ui/elements/FDataGrid";
import {ProductCategoryEditor} from "./editors/ProductCategoryEditor";
import { TablePageLayout } from "../layout/TablePageLayout";
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
export const ProductCategoriesPage = () => {
    const {productCategoriesApi: { forceRefresh, data: categories, loading, error: error, resetApiError, updateItem: updateCategory, deleteItem: deleteCategory, refreshItems: refreshCategories}} = useGlobalApi()
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const columns: any[] = [
        { field: 'id', headerName: '#', width: 50 },
        { field: 'label', headerName: 'Nom', width: 1050, editable: true },
    ]
    return (
        <>
            <TablePageLayout
                title={"CATÉGORIE DE PRODUIT"}
                Editor={ProductCategoryEditor}
                forceRefreshData={forceRefresh}
                errors={error}
                resetError={resetApiError}
                loadingReference={loading}
            >
                <FDataGrid
                    rowModesModel={rowModesModel}
                    setRowModesModel={setRowModesModel}
                    updateRow={updateCategory}
                    deleteRow={deleteCategory}
                    rows={categories}
                    loading={loading}
                    columns={columns}
                    confirmDeleteText={"Attention, la suppression d'une catégorie de produit entraîne la perte de toutes les références de ce type"}
                />
            </TablePageLayout>
        </>
    )
}