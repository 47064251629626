import {ISite} from "../../../../interfaces/ISite";
import {Button} from "../../../ui/inputs/Button";
import React, {useEffect, useState} from "react";
import {useFabApp} from "../../../../hooks/useFabApp";
import {FModal} from "../../../ui/elements/FModal";
import {ICompany} from "../../../../interfaces/ICompany";
import {useCompaniesApi} from "../../../../api/base_apis"

export const ModalSiteChooser: React.FC = () => {
    const {data: companies, loading, error, forceRefresh: forceRefreshCompanies} = useCompaniesApi()
    const {isAuthenticated, siteChosen, setSiteChosen, setCompanyChosen, user} = useFabApp();
    const [selectedSite, setSelectedSite] = useState<ISite | null>(null);
    const [selectedCompany, setSelectedCompany,] = useState<ICompany | null>(null);
    let iOpt = 0;
    const onChangeSelect = (e: any) => {
        let opt = e.target.options[e.target.value]
        if (!opt) return
        let companyId =  opt.getAttribute('data-companyid')
        let siteId = opt.getAttribute('data-siteid')
        let company = companies.find(c => c.id == companyId)
        if (!company) return
        let site = company.Sites.find(s => s.id == siteId)
        if (!site) return;
        setSelectedSite(site)
        setSelectedCompany(company)
    }
    const handleValidSite = () => {
        if(!selectedSite) return;
        setCompanyChosen(selectedCompany)
        setSiteChosen(selectedSite)
    }
    useEffect(() => {
        let site: ISite | undefined = undefined
        let company: ICompany | undefined
        if (!companies) return
        for (let i = 0; i < companies.length && site == undefined; i++) {
            company = companies.at(i)
            site = company?.Sites.at(0)
        }
        if (!company || !site) return
        setSelectedSite(site)
        setSelectedCompany(company)
    }, [companies]);
    return (
        <FModal className="bg-white w-1/3 h-1/3 rounded-md m-auto text-center shadow-2xl" title={"Veuillez choisir un site"} onClose={() => setSiteChosen({id : 0,
            address: "",
            city: "",
            companyId: 0,
            rfidReference: "",
            zipCode: "",
            label: "AUCUN SITE !"})}>
            <>
                {loading && "Chargement des sites..."}
                {error && error.length > 0 &&
                    error.map((error: Error) => {
                        return <p className="p-2 m-2 bg-fab_congress-blue-200 rounded-md border-2 shadow-md border-red-500">{error.message}</p>
                    })
                }
                {companies && companies.length > 0 && !loading &&
                    <select className="w-2/3 fab_button m-auto h-12 mt-10 mb-10 fab_input" onChange={onChangeSelect}>
                        {
                            companies.map((company) => (
                            <optgroup label={company.label}>
                                {
                                    company.Sites.length > 0 && company.Sites.map((site: ISite) => {

                                        let ret =
                                            <option key={site.id} data-companyid={company.id} data-siteid={site.id} value={iOpt}>
                                                {site.label}
                                            </option>
                                        iOpt = iOpt + 1
                                        return ret
                                    })
                                }
                            </optgroup>
                            ))
                        }
                    </select>
                }
                <Button text="Valider" loading={loading} onClick={handleValidSite} className={"w-1/6"}/>
            </>
        </FModal>
    )
}

