import React, {useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {FabOrder} from "../../../interfaces/FabOrder"
import {ArrowLeft, Check, HourglassEmpty, QuestionMark, Rule, TapAndPlay} from "@mui/icons-material"
import {ProductReference} from "../../../interfaces/Product"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
import {Button} from "../../ui/inputs/Button"

export const ProductionFabOrderViewer = () => {
    const {id} = useParams()
    const {
        sabcProductionFabOrderApi: {getItemById: getFabOrder},
        productReferencesApi: {getItemById: getProductReference}
    } = useGlobalApi()
    const navigate = useNavigate()
    const [fabOrder, setFabOrder] = useState<FabOrder | undefined>()
    const [actualProductReference, setActualProductReference] = useState<ProductReference | undefined>()
    useEffect(() => {
        let remoteFabOrder = getFabOrder(Number(id))
        if (remoteFabOrder == undefined) return
        let fabOrderProductReference = getProductReference(Number(remoteFabOrder.Lines[0].productReferenceId))
        setFabOrder(remoteFabOrder)
        setActualProductReference(fabOrderProductReference)
    })
    const goBack = () => {
        window.history.back()
    }
    return (<div className="w-full h-full bg-fab_app_container flex flex-col">
        {
            fabOrder !== undefined ? <>
                    <div className="panel_page_entete">
                        <span className="text-2xl m-auto">{fabOrder.internalOrderId}</span>
                    </div>
                    <div className="panel_page_entete">
                    </div>

                    <div>
                        <div className="w-1/2 m-auto bg-fab_congress-blue-200 mt-10 shadow-md rounded-md border-2">

                            <div className="flex w-full gap-5">
                                    <span className={"text-2xl m-auto w-1/2 text-right"}>Statut : </span>
                                    {fabOrder.statusCode == "NSTA" &&
                                        <span className={"text-2xl m-auto font-bold w-1/2 text-left"}><HourglassEmpty/>En attente</span>}
                                    {fabOrder.statusCode == "STAR" &&
                                        <span className={"text-2xl m-auto font-bold w-1/2 text-left"}><TapAndPlay/> En cours</span>}
                                    {fabOrder.statusCode == "DONE" &&
                                        <span
                                            className={"text-2xl m-auto font-bold w-1/2 text-left"}><Check/>Terminé</span>}
                                    {fabOrder.statusCode == "ECAR" &&
                                        <span
                                            className={"text-2xl m-auto font-bold w-1/2 text-left"}><Rule/>Ecarts</span>}
                                    {fabOrder.statusCode == "" || fabOrder.statusCode == null &&
                                        <span className={"text-2xl m-auto font-bold w-1/2 text-left"}><QuestionMark/>Inconnu</span>}
                            </div>
                            <div className="w-full m-auto flex flex-col">
            <span
                className={"text-2xl m-auto"}>Quantités executées : {fabOrder.Lines[0].quantityDone} / {fabOrder.Lines[0].quantity} </span>
                                <span
                                    className={"text-2xl m-auto"}>Lot : {fabOrder.Lines[0].batch} </span>
                                <span
                                    className={"text-2xl m-auto"}>Référence Produit : {actualProductReference?.label ?? "Inconnu"}</span>
                            </div>
                        </div>
                    </div>


                </>
                :
                <>
                    <span>Undefined</span>
                </>
        }
        <Button text={"Retour"} onClick={goBack} leftIcon={<ArrowLeft/>}/>
    </div>)
}