import {FModal} from "../../../ui/elements/FModal";
import React, {FC} from "react";
import {IFModal} from "../../../ui/interfaces/elements/IFModal";
import {ICustomer} from "../../../../interfaces/ICustomer";
import {Field, FormGenerator} from "../../../ui/elements/FabForm"
import {useGlobalApi} from "../../../../contexts/GlobalApiProvider"

export const CustomerEditor: FC<IFModal> = ({onClose, setModalOpen}) => {
    const {
        customersApi: {addItem: addCustomer, loading, error}
    } = useGlobalApi()

    const fields: Field[] = [
        {label: 'Nom du client', name: 'label', type: 'text', required: true},
        {label: 'Code', name: 'code', type: 'text', required: true},
        {label: 'Addresse', name: 'address', type: 'text', required: false},
        {label: 'Code Postal', name: 'zipCode', type: 'text', required: false},
        {label: 'Ville', name: 'city', type: 'text', required: false},
    ]
    const onValidCustomer = (data: any) => {
        const newCustomer: ICustomer = {
            id: 0,
            label: data.label,
            code: data.code,
            address: data.address,
            zipCode: data.zipCode,
            city : data.city,
            rfidReference: null
        }
        addCustomer(newCustomer).then((r) => {
            if (r && setModalOpen) setModalOpen(false)
            }
        )
    }
    return(<FModal className="fab_editor_modal" canClose onClose={onClose} title={"Ajouter un client"}>
            <div className={"w-full m-auto p-1 mt-10 flex flex-col gap-5"}>
                <FormGenerator loading={loading} fields={fields} onSubmit={onValidCustomer}
                               apiError={error}/>
            </div>
        </FModal>
    )

}