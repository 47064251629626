import React, {FC, SyntheticEvent, useState} from "react";
import {IFModal} from "../../../ui/interfaces/elements/IFModal";
import {FModal} from "../../../ui/elements/FModal";
import {User} from "../../../../interfaces/User";
import {Button} from "../../../ui/inputs/Button";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import {Autocomplete, TextField} from "@mui/material";
import {ICompany} from "../../../../interfaces/ICompany";
import {ISite} from "../../../../interfaces/ISite";
import {useGlobalApi} from "../../../../contexts/GlobalApiProvider"


export const LocalisationEditor: FC<{ user?: User } & IFModal> = ({user, onClose, setModalOpen}) => {
    const {
        usersApi: {updateItem: updateUser, loading},
        companiesApi : {data: companies},
        sitesApi: {data: sites},
    } = useGlobalApi();
    const [sitesFromCompany, setSitesFromCompany] = useState<ISite[]>([]);
    const [companyId, setCompanyId] = useState<number>(0);
    const [siteId, setSiteId] = useState<number>(0);
    const onValidLocalisation = (e: React.MouseEvent<HTMLButtonElement>) => {
        const copyUser = user;
        if (!copyUser) return;
        if (companyId > 0 && siteId > 0) {
            copyUser!.companyId = companyId
            copyUser!.siteId = siteId
            if (setModalOpen) {
                updateUser(copyUser).then(() => setModalOpen(false));
            }
        }
    };
    const onCompanyChange = (e: SyntheticEvent<Element, Event>, newValue: ICompany | null) => {
        e.preventDefault();
        const sitesFiltered = sites.filter(site => site.companyId == newValue?.id ?? 0);
        setSitesFromCompany(sitesFiltered);
        setCompanyId(newValue?.id ?? 0);
    };
    const onSiteChange = (e: SyntheticEvent<Element, Event>, newValue: ISite | null) => {
        e.preventDefault();
        setSiteId(newValue?.id ?? 0);
    };
    return (
        <FModal title={"Modification de la localisation de l'utilisateur " + user?.login}
                className="bg-white w-full rounded-md m-auto text-center shadow-2xl text-xl"
                canClose onClose={onClose}>
            <div className={"w-full m-auto p-1 mt-10"}>
                <div className="fab_editor_modal_input !justify-around">
                    <label className={"w-1/3 text-left border-b"}>Société :</label>
                    <Autocomplete
                        id="combo-box-companies"
                        options={companies}
                        className={"w-2/3 fab_input !border-0"}
                        onChange={onCompanyChange}
                        renderInput={
                            (params) =>
                                <TextField {...params} label="" variant={'standard'}/>
                        }
                    />
                </div>
                <div className="fab_editor_modal_input !justify-around">
                    <label className={"w-1/3 text-left border-b"}>Site :</label>
                    <Autocomplete
                        id="combo-box-sites"
                        options={sitesFromCompany}
                        className={"w-2/3 fab_input !border-0"}
                        onChange={onSiteChange}
                        renderInput={
                            (params) =>
                                <TextField {...params} label="" variant={'standard'}/>
                        }
                    />
                </div>
                <Button onClick={onValidLocalisation} leftIcon={<CheckCircleIcon />} text={"Valider"} loading={loading} className={"mt-2 mb-1 w-1/12"}/>
            </div>
        </FModal>
    );
};