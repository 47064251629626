// @ts-ignore
// noinspection JSUnresolvedReference
export const BASE_URL = window.config.REACT_APP_API_URL || 'http://localhost:3333/'
export const BASE_GET_OPTIONS = {
    method: 'GET',
    headers: new Headers(),
}
export const BASE_POST_OPTIONS = {
    method: 'POST',
    headers: new Headers(),
    body: new FormData()
}
export const BASE_PUT_OPTIONS = {
    method: 'PUT',
    headers: {},
    body: new FormData()
}
export const BASE_DELETE_OPTION = {
    method: 'DELETE',
    headers: new Headers(),
    body: new FormData()
}
export const COL_LABELS: Record<string, string> = {
    id: "#",
    internalOrderId: "Identifiant Ordre",
    fkSite: "Id Site",
    createdAt: "Création",
    updatedAt: "Modification"
}
export const formatDate = (date: string) => {
    return new Date(date);
}