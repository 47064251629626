import { IFAutoComplete } from "../interfaces/inputs/IFAutoComplete";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Identifiable } from "../../../interfaces/Identifiable";

export function FAutoComplete<T extends Identifiable>({
                                                          className,
                                                          label,
                                                          data,
                                                          dataCustom,
                                                          onSelectionChange,
                                                          valueId,
                                                          isOptionEqualToValue,
                                                      }: IFAutoComplete<T>) {
    const [value, setValue] = useState<T | null>(null); // Initialiser avec null

    // Fonction de gestion de la sélection locale
    const onLocalSelectionChange = (
        e: SyntheticEvent<Element, Event>,
        newValue: T | null
    ) => {
        setValue(newValue); // Met à jour la valeur locale
        if (dataCustom !== undefined) {
            onSelectionChange({ e, newValue, dataCustom });
        } else {
            onSelectionChange({ e, newValue});
        }
    };

    // Mettre à jour l'état lorsque valueId change
    useEffect(() => {
        if (valueId == undefined) {
            setValue(null)
            return
        }
        const foundValue = data.find((i) => i.id === valueId) || null; // Cherche la valeur
        setValue(foundValue); // Met à jour l'état
    }, [valueId, data]);

    return (
        <div className={className} data-custom={dataCustom}>
            {label && (
                <div className="w-1/3 text-left m-auto border-b pb-2">{label}</div>
            )}
            <Autocomplete
                options={data}
                value={value}
                isOptionEqualToValue={isOptionEqualToValue} // Utilisation de la prop
                className="fab_input w-2/3 !border-transparent"
                onChange={onLocalSelectionChange}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                        {option.label}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                )}
            />
        </div>
    );
}
