import React, {useEffect, useState} from "react"
import {GridColDef, GridRowModesModel, GridRowParams} from '@mui/x-data-grid'
import {FDataGrid} from '../../ui/elements/FDataGrid'
import {Tag} from "../../../interfaces/Tag"
import {HourglassEmpty, Inventory, LocalShipping, QuestionMark} from "@mui/icons-material"
import {FCheckBox} from "../../ui/inputs/FCheckBox"
import {TablePageLayout} from "../../base/layout/TablePageLayout"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
import {Button} from "../../ui/inputs/Button"
import {useNavigate} from "react-router-dom"

export const ContainerList = () => {
    const {
        taggedContainersApi: {
            data: containers,
            loading,
            error,
            resetApiError,
            refreshItems: refreshContainers,
            forceRefresh: forceRefreshContainers
        },
        productReferencesApi: {data: productReferences, getItemById: getProductReference},
        fabOrdersApi: {data: orders, getItemById: getOrder, forceRefresh: forceRefreshOrders}
    } = useGlobalApi()
    const [dataLoading, setDataLoading] = React.useState<boolean>(loading)
    const [dataFiltered, setDataFiltered] = React.useState<Tag[]>(containers)
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
    const [filterStates, setFilterStates] = React.useState({
        all: true,
        case: false,
        pallet: false,
        waiting: true,
        production: true,
        sends: true,
    })
    const [columns, setColumns] = useState<GridColDef[]>([{
        field: 'id',
        headerName: '#',
        editable: false,
        hideable: true
    }])
    const navigate = useNavigate()
    useEffect(() => {
        forceRefreshOrders()
        forceRefreshContainers()
    }, [])
    useEffect(() => {
        if (productReferences.length == 0) return
        if (containers.length == 0) return
        if (orders.length == 0) return
        setColumns([
            {
                field: 'type', headerName: 'Type', editable: false, width: 50, renderCell: (params: any) => {
                    if (params.row.rfidValue.startsWith('PA')) {
                        return <span title={"Palette"}
                                     className="mt-3.5 material-symbols-outlined m-auto hover:cursor-help">pallet</span>
                    } else if (params.row.rfidValue.startsWith('CA')) {
                        return <span title={"Casier"}
                                     className="mt-3.5 material-symbols-outlined m-auto cursor-help">package_2</span>
                    }
                }
            },
            {field: "rfidValue", headerName: 'Identifiant', editable: true, width: 300},
            {
                field: 'statusCode', headerName: 'Status', editable: true, width: 250, renderCell: (params: any) => {
                    switch (params.formattedValue) {
                        case "PROD" :
                            return <span className={"font-bold"}><Inventory/> Produit</span>
                        case "EXPE" :
                            return <span className={"font-bold"}><LocalShipping/> Expedié</span>
                        case "DISP" :
                            return <span className={"font-bold"}><HourglassEmpty/>Disponible</span>
                        default :
                            return <span className={"font-bold"}><QuestionMark/>Inconnu</span>
                    }
                }
            },
            {
                field: 'productReferenceId',
                headerName: 'Produit',
                editable: true,
                width: 300,
                renderCell: (params: any) => {
                    const label = getProductReference(params.formattedValue)?.label
                    return label ?? "N/A"
                }
            },
            {
                field: 'fabOrderId',
                headerName: 'Ordre',
                type: 'actions',
                cellClassName: 'actions',
                editable: true,
                width: 150,
                getActions: (params: GridRowParams) => {
                    const label = getOrder(params.row.fabOrderId)?.internalOrderId
                        return [<Button
                            text={label ?? "N/A"}
                            disabled={!label}
                            onClick={() => {
                                if (params.row.statusCode == "EXPE") {
                                    if (params.row.fabOrderId > 0) {
                                        navigate(`/dashboard/shipping/${params.row.fabOrderId}`)
                                    }
                                } else if (params.row.statusCode == "PROD") {
                                    if (params.row.fabOrderId > 0) {
                                        navigate(`/dashboard/production/${params.row.fabOrderId}`)
                                    }
                                }

                            }}
                        />]
                }
            }
        ])
    }, [productReferences, containers, orders])
    const [modalAddCustomerOpen, setModalAddCustomerOpen] = useState<boolean>(false)
    useEffect(() => {
        setDataLoading(loading)
    }, [loading])
    const allContainers = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, all: true, case: false, pallet: false}))
    }
    const allCases = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, all: false, case: !prevState.case}))
    }
    const allPallets = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, all: false, pallet: !prevState.pallet}))
    }
    const allWaiting = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, waiting: !prevState.waiting}))
    }
    const allProduce = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, production: !prevState.production}))
    }
    const allSends = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, sends: !prevState.sends}))
    }

    useEffect(() => {
        let filteredData: Tag[] = []
        let containersData: Tag[] = []
        if (filterStates.all) {
            containersData = containers
        } else {
            if (filterStates.case) {
                containersData.push(...containers.filter((value) => value.rfidValue.startsWith('CA')))
            }
            if (filterStates.pallet) {
                containersData.push(...containers.filter((value) => value.rfidValue.startsWith('PA')))
            }
        }
        if (filterStates.waiting) {
            filteredData.push(...containersData.filter((value) => (value.statusCode == "DISP")))
        }
        if (filterStates.production) {
            filteredData.push(...containersData.filter((value) => (value.statusCode == "PROD")))
        }
        if (filterStates.sends) {
            filteredData.push(...containersData.filter((value) => (value.statusCode == "EXPE")))
        }
        setDataFiltered(filteredData)
        setDataLoading(false)
    }, [filterStates, containers])

    useEffect(() => {
        if (!modalAddCustomerOpen) refreshContainers().then(r => r)
    }, [modalAddCustomerOpen])
    return (
        <>
            <TablePageLayout
                title={"CASIERS ET PALETTES"}
                forceRefreshData={forceRefreshContainers}
                errors={error}
                resetError={resetApiError}
                loadingReference={loading}
                checkboxs={<>
                    <FCheckBox
                        label={'Tout Types'}
                        onChange={allContainers}
                        state={filterStates.all}
                    />
                    <FCheckBox
                        label={'Casiers'}
                        onChange={allCases}
                        state={filterStates.case}
                    />
                    <FCheckBox
                        label={'Palettes'}
                        onChange={allPallets}
                        state={filterStates.pallet}
                    />
                    <div className="w-1 rounded-md h-1/2 bg-gray-400"/>
                    <FCheckBox
                        label={'Disponible'}
                        onChange={allWaiting}
                        state={filterStates.waiting}
                    />
                    <FCheckBox
                        label={'Produit'}
                        onChange={allProduce}
                        state={filterStates.production}
                    />
                    <FCheckBox
                        label={'Expedié'}
                        onChange={allSends}
                        state={filterStates.sends}
                    />
                </>}>
                <FDataGrid
                    disableEdit
                    rowModesModel={rowModesModel}
                    setRowModesModel={setRowModesModel}
                    rows={dataFiltered}
                    loading={dataLoading}
                    columns={columns}/>
            </TablePageLayout>
        </>
    )
}