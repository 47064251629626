import React, { ReactNode, useRef, useState } from 'react';
import {KeyboardArrowRight} from '@mui/icons-material';
import {IButton} from "../../ui/interfaces/inputs/IButton"
import {FNavButton} from "../../ui/inputs/FNavButton"

export const NavSection: React.FC<{ title: string; navButtons: IButton[] }> = ({ title, navButtons }) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };
    const forceOpen = () => {
        setIsOpen(true)
    }

    return (
        <>
            <div className="rounded-md-full flex p-1 cursor-pointer" onClick={toggleOpen}>
                <KeyboardArrowRight
                    className={`transform transition-transform duration-100 ${isOpen ? 'rotate-90' : ''}`}
                />
                <span className="text-md font-bold text-fab_congress-blue-500">{title}</span>
            </div>
            <div
                ref={contentRef}
                style={{ maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : '0' }}
                className={`transition-max-height duration-500 ease-in-out overflow-hidden`}
            >
                {
                    navButtons && navButtons.length > 0 && navButtons.map( navButton => {
                        return <FNavButton
                            leftIcon ={navButton.leftIcon}
                            text={navButton.text}
                            route={navButton.route}
                            forceOpen={forceOpen}
                        />
                    })
                }
            </div>
        </>
    );
};
