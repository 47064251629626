import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {FabOrder, FabOrderLine} from "../../../interfaces/FabOrder";
import {ArrowLeft, Check, HourglassEmpty, QuestionMark, Rule, TapAndPlay} from "@mui/icons-material";
import {Button} from "../../ui/inputs/Button";
import {FDataGrid} from "../../ui/elements/FDataGrid";
import {GridColDef, GridRowModesModel} from "@mui/x-data-grid";
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
import {ArrowPathIcon} from "@heroicons/react/24/solid"

export const ShippingFabOrderViewer = () => {
    const {
        sabcShippingFabOrderApi: {data:orders, getItemById: getFabOrder, forceRefresh: forceRefreshShippingOrder, loading},
        productReferencesApi: {data: productReferences, getItemById: getProductReference},
        customersApi: {getItemById: getCustomer},
    } = useGlobalApi()
    const {id} = useParams();
    const navigate = useNavigate();
    const [fabOrder, setFabOrder] = useState<FabOrder | undefined>();
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [orderLines, setOrderLines] = useState<FabOrderLine[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>([]);

    useEffect(() => {
        setColumns(([
            {
                field: 'product', headerName: 'Produit', editable: true, width: 500, renderCell: (params: any) => {
                    let label = getProductReference(params.row.productReferenceId)?.label ?? "N/A";
                    return label;
                }
            },
            {
                field: 'qty', headerName: 'État quantités', editable: true, width: 500, renderCell: (params: any) => {
                    return `${params.row.quantityDone} / ${params.row.quantity}`;
                }
            },

        ]));
    }, [productReferences]);

    useEffect(() => {
        let remoteFabOrder = getFabOrder(Number(id));
        if (remoteFabOrder == undefined) return;
        setFabOrder(remoteFabOrder);
        let lines = remoteFabOrder.Lines.slice(1);
        setOrderLines(lines);
    }, [orders]);
    const goBack = () => {
        window.history.back()
    };
    return (<div className="w-full h-full bg-fab_app_container flex flex-col">
        {
            fabOrder !== undefined ? <>
                    <div className="panel_page_entete">
                        <span className="text-2xl m-auto">{fabOrder.internalOrderId}</span>
                    </div>
                    <Button onClick={forceRefreshShippingOrder} loading={loading} className={"w-1/3"}
                            text={"Rafraichir"}
                            leftIcon={<ArrowPathIcon/>}/>
                    <div>
                        <div className="w-1/2 m-auto bg-fab_congress-blue-200 mt-10 shadow-md rounded-md border-2">

                            <div className="flex w-full gap-5">
                                <span className={"text-2xl m-auto w-1/2 text-right"}>Statut : </span>
                                {fabOrder.statusCode == "NSTA" &&
                                    <span className={"text-2xl m-auto font-bold w-1/2 text-left"}><HourglassEmpty/>En attente</span>}
                                {fabOrder.statusCode == "STAR" &&
                                    <span className={"text-2xl m-auto font-bold w-1/2 text-left"}><TapAndPlay/> En cours</span>}
                                {fabOrder.statusCode == "DONE" &&
                                    <span className={"text-2xl m-auto font-bold w-1/2 text-left"}><Check/>Terminé</span>}
                                {fabOrder.statusCode == "ECAR" &&
                                    <span className={"text-2xl m-auto font-bold w-1/2 text-left"}><Rule/>Ecarts</span>}
                                {fabOrder.statusCode == "" || fabOrder.statusCode == null &&
                                    <span className={"text-2xl m-auto font-bold w-1/2 text-left"}><QuestionMark/>Inconnu</span>}
                            </div>

                            <div className="flex w-full gap-5">
                                <span className={"text-2xl m-auto w-1/2 text-right"}>Client / Destinataire :</span>
                                <span className={"text-2xl m-auto font-bold w-1/2 text-left"}>{getCustomer(fabOrder.Lines[0].customerId)?.label}</span>
                            </div>

                        </div>

                    </div>

                    <div className="w-full m-auto h-1/2 flex flex-col">
                        <FDataGrid
                            rowModesModel={rowModesModel}
                            setRowModesModel={setRowModesModel}
                            rows={orderLines}
                            loading={false}
                            columns={columns}
                            disableEdit
                        />

                    </div>
                </>
                :
                <>
                    <span>Undefined</span>
                </>
        }
        <Button text={"Retour"} onClick={goBack} leftIcon={<ArrowLeft/>}/>
    </div>);
};