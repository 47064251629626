import {TablePageLayout} from "../../base/layout/TablePageLayout"
import {FDataGrid, IFDataGrid} from "../../ui/elements/FDataGrid"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
import React, {useEffect, useState} from "react"
import {Check, HourglassEmpty, QuestionMark, TapAndPlay} from "@mui/icons-material"
import {formatDate} from "../../../api/static_vars"
import {FabOrder, FabOrderLine} from "../../../interfaces/FabOrder"
import {GridColDef, GridRowModesModel} from "@mui/x-data-grid"
import {useNavigate} from "react-router-dom"
import {Field} from "../../ui/elements/FabForm"

export const TraceabilityPage = () => {
    const {
        productReferencesApi: {data: productReferences, getItemById: getProductReference},
        fabOrdersApi: {
            data: orders,
            loading,
            error,
            resetApiError,
            refreshItems: refreshOrders,
            forceRefresh: forceRefreshOrders,
            deleteItem: deleteFabOrder,
            getItemById: getOrderById
        },
        customersApi: {
            getItemById: getCustomer,
            data: customers,
        }
    } = useGlobalApi()
    const [dataLoading, setDataLoading] = React.useState<boolean>(loading)
    const [dataFiltered, setDataFiltered] = React.useState<FabOrder[]>(orders)
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const navigate = useNavigate()
    const [columns, setColumns] = useState<GridColDef[]>([{
        field: 'id',
        headerName: '#',
        editable: false,
        hideable: true
    }])
    useEffect(() => {
        setColumns(
            [
                {field: 'id', headerName: '#', editable: false, hideable: true},
                {field: 'internalOrderId', headerName: 'Identifiant', editable: true, width: 200},
                {
                    field: 'statusCode',
                    headerName: 'Status',
                    editable: true,
                    width: 300,
                    renderCell: (params: any) => {
                        switch (params.formattedValue) {
                            case "NSTA" :
                                return <span className={"font-bold"}><HourglassEmpty/>En attente</span>
                            case "STAR" :
                                return <span className={"font-bold"}><TapAndPlay/> En cours</span>
                            case "DONE" :
                                return <span className={"font-bold"}><Check/>Cloturé</span>
                            default :
                                return <span className={"font-bold"}><QuestionMark/>Inconnu</span>
                        }

                    }
                },
                {
                    field: 'executionDate',
                    headerName: 'Date de production',
                    type: 'date',
                    width: 200,
                    valueGetter: (params) => {
                        if (params == null) return null
                        else return formatDate(params)
                    }
                },
                {
                    field: 'closingDate',
                    headerName: 'Date de clôture',
                    type: 'dateTime',
                    width: 200,
                    valueGetter: (params) => {
                        if (params == null) return null
                        else return formatDate(params)
                    }
                },
                {
                    field: 'quantity_state',
                    headerName: 'Etat Quantité',
                    editable: true,
                    width: 150,
                    renderCell: (params: any) => {
                        if (params.row.Lines.length == 0) return "N/A"
                        if (params.row.Lines.length > 1) {
                            let linesWithoutHeader = [...params.row.Lines.slice(1)]
                            return `${linesWithoutHeader.reduce((prev, cur) => prev + cur.quantityDone, 0)}/${linesWithoutHeader.reduce((prev, cur) => prev + cur.quantity, 0)}`;
                        } else return `${params.row.Lines[0].quantityDone}/${params.row.Lines[0].quantity}`
                    }
                },
                {
                    field: 'batch_header',
                    headerName: 'Lot',
                    editable: false,
                    width: 150,
                    renderCell: (params: any) => {
                        if (params.row.Lines.length == 0) return "N/A"
                        return params.row.Lines[0].batch
                    }
                },
                {
                    field: 'product',
                    headerName: 'Référence produit',
                    editable: false,
                    width: 250,
                    renderCell: (params: any) => {
                        if (params.row.Lines.length == 0) return "N/A"
                        return getProductReference(params.row.Lines[0].productReferenceId)?.label
                    }
                },
                {
                    field: 'customer',
                    headerName: 'Client',
                    editable: false,
                    width: 250,
                    renderCell: (params: any) => {
                        if (params.row.Lines.length > 1) {
                            return getCustomer(params.row.Lines[0].customerId)?.label ?? "NON"
                        } else return  "N/A"
                    }
                }
            ]
        )
    }, [productReferences])
    useEffect(() => {
        setDataFiltered(orders)
    }, [orders])
    const showMore = (id: number) =>  {
        let order = getOrderById(id)
        if (order) {
            if (order.internalOrderId.startsWith("BP")) {
                navigate(`/dashboard/shipping/${id}`)
            } else {
                navigate(`/dashboard/production/${id}`)
            }
        }
    }
    const dataGrid: IFDataGrid = {
        rowModesModel: rowModesModel,
        setRowModesModel: setRowModesModel,
        rows: dataFiltered,
        loading: dataLoading,
        deleteRow: deleteFabOrder,
        columns: columns,
        showMore: showMore,
        tableHeight: 'h-[60%]'
    }
    const [filterInputs, setFilterInputs] = useState<Field[]>([
        {
            label: "Nom de l'ordre",
            type: 'text',
            required: false,
            name: 'orderInternalId',
            active: false,
            value: ""
        },
        {
            label: 'Lot',
            type: 'text',
            required: false,
            name: 'batch',
            active: false,
            value: ""
        },
        {
            label: 'Date de création après',
            type: 'date',
            required: false,
            name: 'createdAt',
            active: false,
            value: ""
        },
        {
            label: 'Date de cloture après',
            type: 'date',
            required: false,
            name: 'closingDate',
            active: false,
            value: ""
        },
        {
            label: "Date d'éxecution après",
            type: 'date',
            required: false,
            name: 'executionDate',
            active: false,
            value: ""
        },
        {
            label: 'Article',
            type: 'autocomplete',
            required: false,
            data: productReferences,
            name: 'productReferenceId',
            active: false,
            value: ""
        },
        {
            label: 'Client',
            type: 'autocomplete',
            required: false,
            data: customers,
            name: 'customerId',
            active: false,
            value: ""
        },
    ])
    useEffect( () => {
        let fromValues = [...orders]
        filterInputs.forEach( filter => {
            if (filter.value != undefined && filter.value != "" && filter.active) {
                switch (filter.name) {
                    case 'orderInternalId':
                        fromValues = fromValues.filter(order =>
                            order.internalOrderId && order.internalOrderId.toLowerCase().includes(filter.value!.toLowerCase())
                        )
                        break
                    case 'batch':
                        fromValues = fromValues.filter(order =>
                            order.Lines[0].batch && order.Lines[0].batch.toLowerCase().includes(filter.value!.toLowerCase())
                        )
                        break
                    case 'createdAt':
                        const toCompareCreate = new Date(filter.value!)
                        fromValues = fromValues.filter(order => {
                            const orderDate = new Date(order.createdAt!)
                            return orderDate > toCompareCreate
                        })
                        break
                    case 'closingDate':
                        const toCompareClosing = new Date(filter.value!)
                        fromValues = fromValues.filter( order => {
                            if (order.closingDate != undefined) {
                                const orderDate = new Date(order.closingDate)
                                return orderDate > toCompareClosing
                            } else return false
                        })
                        break
                    case 'executionDate':
                        const toCompareExecution = new Date(filter.value!)
                        fromValues = fromValues.filter( order => {
                            if (order.executionDate != undefined) {
                                const executionDate = new Date(order.executionDate)
                                return executionDate > toCompareExecution
                            } else return false
                        })
                        break
                    case 'productReferenceId':
                        fromValues = fromValues.filter(order => order.Lines.find(line => line.productReferenceId === Number(filter.value!)))
                        break
                    case 'customerId':
                        fromValues = fromValues.filter(order => order.Lines[0].customerId == Number(filter.value!))
                        break
                }
            }
        })
        setDataFiltered(fromValues)
    }, [filterInputs, orders])
    const onChangeFilterInputOverload = (column: string, value: string) => {
        const newFilters = [...filterInputs]
        if (orders.length > 0) {
            const filterInputIndex = newFilters.findIndex(filter => filter.name === column)
            if (newFilters[filterInputIndex] != null)
            {
                if (value != "" || value != null) {
                    newFilters[filterInputIndex].active = true
                    newFilters[filterInputIndex].value = value
                } else {
                    newFilters[filterInputIndex].active = false
                    newFilters[filterInputIndex].value = undefined
                }
            }
            setFilterInputs(newFilters)
        }
    }
    return (<>
        <TablePageLayout
            title={"TRACABILITÉ"}
            forceRefreshData={forceRefreshOrders}
            errors={[]}
            resetError={resetApiError}
            loadingReference={loading}
            filterInputs={filterInputs}
            table={dataGrid}
            onChangeFilterInputOverload={onChangeFilterInputOverload}
        />
    </>)
}