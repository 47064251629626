import {FModal} from "../../../ui/elements/FModal"
import React, {FC} from "react"
import {IFModal} from "../../../ui/interfaces/elements/IFModal"
import {Role} from "../../../../interfaces/Role"
import {Field, FormGenerator} from "../../../ui/elements/FabForm"
import {useGlobalApi} from "../../../../contexts/GlobalApiProvider"

export const RoleEditor: FC<IFModal> = ({onClose, setModalOpen}) => {
    const {
        rolesApi: {addItem: addRole, loading, error}
    } = useGlobalApi()
    const fields: Field[] = [
        {label: 'Nom du rôle', name: 'label', type: 'text', required: true},
        {label: 'Accès Web', name: 'webAccess', type: 'checkbox', required: false},
        {label: 'Accès mobile', name: 'mobileAccess', type: 'checkbox', required: false},
        {label: 'Accès Administrateur', name: 'adminAccess', type: 'checkbox', required: false},
    ]
    const onValidRole = (data: any) => {
        const roleToSend: Role = {
            id: 0,
            label: data.label,
            webAccess: data.webAccess === "on",
            mobileAccess: data.mobileAccess === "on",
            adminAccess: data.adminAccess === "on",
            teamManager: false,
        }
        addRole(roleToSend).then((r) => {
            if (r && setModalOpen) setModalOpen(false)
        })
    }
    return (<FModal title={"Ajout d'un rôle"}
                    className="fab_editor_modal"
                    canClose onClose={onClose}>
            <div className={"w-full m-auto p-1 mt-10 flex flex-col gap-5"}>
                <FormGenerator loading={loading} fields={fields} onSubmit={onValidRole}
                               apiError={error}/>
            </div>
        </FModal>
    )

}