import {FModal} from "../../../ui/elements/FModal";
import React, {FC} from "react";
import {IFModal} from "../../../ui/interfaces/elements/IFModal";
import {ISite} from "../../../../interfaces/ISite";
import {Field, FormGenerator} from "../../../ui/elements/FabForm"
import {useGlobalApi} from "../../../../contexts/GlobalApiProvider"

export const SiteEditor: FC<IFModal> = ({onClose, setModalOpen}) => {
    const {
        sitesApi: {addItem: addSite, loading, error, resetApiError},
        companiesApi: {data: companies}
    } = useGlobalApi()
    const fields: Field[] = [
        {label: 'Nom du site', name: 'label', type: 'text', required: true},
        {label: 'Addresse', name: 'address', type: 'text', required: false},
        {label: 'Code postal', name: 'zipCode', type: 'text', required: false},
        {label: 'Ville', name: 'city', type: 'text', required: false},
        {
            label: 'Société',
            name: 'companyId',
            type: 'autocomplete',
            required: true,
            data: companies
        },
    ]

    const onValidSite = (data: any) => {
        const newSite: ISite = {
            id: 0,
            label: data.label,
            address: data.address,
            zipCode: data.zipCode,
            city : data.city,
            companyId: data.companyId,
            rfidReference: null
        }
        addSite(newSite).then(r => {
            if (r && setModalOpen) setModalOpen(false)
        })

    }
    return(<FModal className="fab_editor_modal" canClose onClose={onClose} title={"Ajouter un site"}>
        <div className={"w-full m-auto p-1 mt-10 flex flex-col gap-5"}>
            <FormGenerator loading={loading} fields={fields} onSubmit={onValidSite}
                           apiError={error} resetApiError={resetApiError}/>
        </div>
        </FModal>
    )

}