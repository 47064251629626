import React, {ChangeEvent, ChangeEventHandler, MouseEventHandler, useEffect, useState} from "react"
import {Button} from "../../ui/inputs/Button"
import {ArrowPathIcon, PlusCircleIcon} from "@heroicons/react/24/solid"
import {IFModal} from "../../ui/interfaces/elements/IFModal"
import {FModal} from "../../ui/elements/FModal"
import {FDataGrid, IFDataGrid} from "../../ui/elements/FDataGrid"
import {Field, FormGenerator} from "../../ui/elements/FabForm"

interface IFilterInput {
    label: string,
    column: string,
}

interface ITablePageLayoutProps {
    title: string,
    Editor?: React.FC<IFModal>,
    checkboxs?: React.ReactNode,
    forceRefreshData: () => void,
    errors: Error[] | null,
    resetError: () => void,
    loadingReference: boolean,
    children?: React.ReactNode,
    table?: IFDataGrid,
    filterInputs?: Field[]
    onChangeFilterInputOverload?: (name: string, newValue: string) => void
}

export const TablePageLayout: React.FC<ITablePageLayoutProps> = ({
                                                                     title,
                                                                     Editor,
                                                                     checkboxs,
                                                                     table,
                                                                     filterInputs,
                                                                     forceRefreshData,
                                                                     children,
                                                                     errors,
                                                                     resetError,
                                                                     loadingReference,
                                                                     onChangeFilterInputOverload
                                                                 }) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const onCloseModal: MouseEventHandler<HTMLButtonElement> = (e) => {
        setModalOpen(false)
    }
    const openModal = () => {
        setModalOpen(true)
    }
    return (<>
        <div className="w-full">
            <div className="panel_page_entete">
                <span className="text-2xl m-auto">{title}</span>
            </div>
            <div className="flex w-full justify-around m-auto mb-5">
                {Editor && <Button onClick={openModal} className={"w-1/3"} text={`Ajouter un(e) ${title.toLowerCase()}`}
                                   leftIcon={<PlusCircleIcon/>}/>}
                <Button onClick={() => forceRefreshData()} loading={loadingReference} className={"w-1/6"}
                        text={"Rafraichir"}
                        leftIcon={<ArrowPathIcon/>}/>
            </div>
            {checkboxs &&
                <div className="w-1/2 flex justify-around items-center m-auto mt-2 mb-2 bg-white">
                    {checkboxs}
                </div>
            }
            {filterInputs && filterInputs.length > 0 &&
                <div className="w-full flex justify-around items-center m-auto mt-2 pb-4 bg-white">
                    <FormGenerator flex size={"!w-[48%]"} fields={filterInputs}
                                   onChangeOverload={onChangeFilterInputOverload} apiError={[]}/>
                </div>
            }

        </div>
        {table && <FDataGrid rows={table.rows} loading={table.loading} columns={table.columns}
                             showMore={table.showMore} disableEdit={table.disableEdit} tableHeight={table.tableHeight}
                             rowModesModel={table.rowModesModel} setRowModesModel={table.setRowModesModel}/>}
        {children && children}
        {errors && errors.length > 0 && !modalOpen &&
            <FModal
                className="bg-white w-3/12 rounded-md m-auto flex flex-col justify-center text-center shadow-2xl !mt-5"
                canClose onClose={resetError} title={"Erreurs"}>
                {
                    errors.map(error => {
                        return <p
                            className="p-2 m-2 bg-fab_congress-blue-200 rounded-md border-2 shadow-md border-red-500">{error.message}</p>
                    })
                }
            </FModal>
        }
        {
            Editor && modalOpen && <Editor onClose={onCloseModal} setModalOpen={setModalOpen}/>
        }

    </>)
}