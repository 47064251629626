import {
    ArrowsRightLeftIcon,
    BuildingOfficeIcon,
    MapPinIcon,
    PresentationChartLineIcon
} from "@heroicons/react/24/solid";
import {useFabApp} from "../../../hooks/useFabApp";
import React from "react";
import useLocalStorage from "../../../hooks/useLocalStorage";
import {FNavButton} from '../../ui/inputs/FNavButton';
import {
    Category,
    DeveloperMode,
    Inventory2,
    Key, LocalShipping,
    LogoutOutlined,
    Person, PrecisionManufacturing,
    Store
} from "@mui/icons-material";
import {GridMenuIcon} from "@mui/x-data-grid";
import {Button} from "../../ui/inputs/Button";
import {NavSection} from "./NavSection";


export const NavBar = () => {
    const {siteChosen, setSiteChosen, setIsAuth, user} = useFabApp();
    const [, setLocalTokenItem] = useLocalStorage('fabToken', "");
    const resetSite = () => {
        setSiteChosen(null);
    };
    const logout = () => {
        setLocalTokenItem("");
        setIsAuth(false);
    };
    return (
        <div className={"fab_navbar"}>
            <div className="">
                <div className="w-full h-min-full flex mt-1 justify-around">
                    <div
                        className="p-2.5 w-16 h-16 bg-fab_congress-blue-800 rounded-full flex flex-col border-2 border-white text-center shadow-xl relative">
                    <span className=" text-3xl m-auto opacity-100 text-white">
                        {user?.fullName?.charAt(0) ?? user?.login?.charAt(0)}
                    </span>
                    </div>
                </div>
                <div className="w-full m-auto pr-5 pl-5 text-white gap-2">
                    <div className="h-full text-black flex-1 rounded-lg m-auto">
                        <b>Utilisateur : </b>{user?.fullName ?? user?.login} <br />
                        <b>Rôle : </b> {user?.Role?.label}
                    </div>
                    <Button text="Déconnexion" leftIcon={<LogoutOutlined />} className={"h-1/2 w-full"} fullStyle
                            onClick={logout} />
                </div>
                <div className="text-center w-10/12 m-auto flex align-middle mt-2 ">
                    <span className="m-auto pr-2 pl-2 h-1/2 w-1/2 rounded-lg text-black">Site : </span>
                    <Button onClick={resetSite} fullStyle text={siteChosen ? siteChosen.label : "Aucun"} />
                </div>
            </div>
            <div className="w-full m-auto h-2 bg-fab_congress-blue-500 mt-2 mb-2" />
            <div className="flex flex-col h-min-full w-full">
                {user != null && user.Role?.webAccess && (
                    <FNavButton leftIcon={<PresentationChartLineIcon className="h-6 pr-2" />} text="Tableau de bord"
                                route="/dashboard" forceOpen={() => {}} />
                )}
                {user != null && user.Role?.webAccess && (
                    <NavSection title="Production" navButtons={[
                        { leftIcon: <PrecisionManufacturing className="h-6 pr-2" />, text: "Fabrications", route: "production" },
                        { leftIcon: <LocalShipping className="h-6 pr-2" />, text: "Expéditions", route: "shipping" },
                        { leftIcon: <ArrowsRightLeftIcon className="h-6 pr-2" />, text: "Mouvements", route: "movements" },
                        { leftIcon: <ArrowsRightLeftIcon className="h-6 pr-2" />, text: "Tracabilité", route: "traceability" }
                    ]} />
                )}
                {user != null && user.Role?.webAccess && (
                    <NavSection title="Alertes" navButtons={[
                        { leftIcon: <PrecisionManufacturing className="h-6 pr-2" />, text: "Fabrications", route: "alerts_production" },
                        { leftIcon: <LocalShipping className="h-6 pr-2" />, text: "Expéditions", route: "alerts_shipping" }
                    ]} />
                )}
                {user != null && user.Role?.adminAccess && (
                    <NavSection title="Logistique" navButtons={[
                        { leftIcon: <Inventory2 className="h-6 pr-2" />, text: "Casiers & Palettes", route: 'sabc/containers' },
                        { leftIcon: <BuildingOfficeIcon className="h-6 pr-2" />, text: "Sociétés", route: "companies" },
                        { leftIcon: <MapPinIcon className="h-6 pr-2" />, text: "Sites", route: "sites" },
                        { leftIcon: <Store className="h-6 pr-2" />, text: "Clients", route: "customers" },
                        { leftIcon: <Category className="h-6 pr-2" />, text: "Catégories de produits", route: 'product_categories' },
                        { leftIcon: <Inventory2 className="h-6 pr-2" />, text: "Références de produits", route: 'product_references' }
                    ]} />
                )}
                {user != null && user.Role?.adminAccess && (
                    <NavSection title="Utilisateurs" navButtons={[
                        { leftIcon: <Key className="h-6 pr-2" />, text: "Rôles", route: "roles" },
                        { leftIcon: <Person className="h-6 pr-2" />, text: "Utilisateurs", route: 'users' }
                    ]} />
                )}
                {user != null && user.superUser && (
                    <NavSection title="Paramètrages" navButtons={[
                        { leftIcon: <GridMenuIcon className="h-6 pr-2" />, text: "Type de contenant", route: 'container_types' },
                        { leftIcon: <DeveloperMode className="h-6 pr-2" />, text: "Gestion des terminaux", route: 'devices' }
                    ]} />
                )}
            </div>
        </div>
    );
};